<template>
    <div>
        <b-container>
            <section class="statistics">
                <div class="sector">
                    <h2 class="main-title">Statistics</h2>
                    <p class="main-subtitle">Analyse market metrics</p>
                    <b-row>
                        <b-col colx='12'>
                            <b-card no-body>
                                <b-tabs card>
                                    <!-- <b-tab title="Leaders Martix*">
                                        <div class="statistics__chart">
                                            <apexchart type=scatter height=550 :options="matrixChart" :series="matrixSeries" />
                                        </div>
                                        <div>
                                            <span class="profile__label float-right mt-2">*According to a Branding Pavilion study</span>
                                        </div>
                                    </b-tab> -->
                                    <b-tab title="Market statistics" active>
                                        <div class="statistics__chart mb-3">
                                            <apexchart type=donut height=400 :options="mediumChart" :series="mediumSeries" />
                                        </div>
                                        <div class="statistics__chart mb-3">
                                            <apexchart type=bar height=450 :options="trendsChart" :series="trendsSeries" />
                                        </div>
                                        <div class="statistics__chart mb-3">
                                            <apexchart type=bar height=450 :options="marketChart" :series="marketSeries" />
                                        </div>
                                        <div class="statistics__chart mb-3">
                                            <apexchart type=bar height=450 :options="digitalChart" :series="digitalSeries" />
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import router from '../router/router'
export default {
  components: {
    'apexchart': VueApexCharts
  },
  data: function () {
    return {
      matrixSeries: [
        {
          name: 'Extra nova',
          data: [
            [77.6, 30.4]
          ]
        }],
      matrixChart: {
        chart: {
          animations: {
            enabled: false
          },
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        xaxis: {
          tickAmount: 10,
          min: 0,
          max: 100,
          title: {
            text: 'Strategy & Planning'
          }
        },
        yaxis: {
          tickAmount: 10,
          title: {
            text: 'Efficiency & Pperformance'
          },
          tooltip: {
            enabled: true,
            formatter: function (val) {
              return 'Efficiency & performance: ' + val
            }
          }
        },
        markers: {
          size: 15,
          hover: {
            size: 15,
            sizeOffset: 0
          }
        },
        fill: {
          colors: ['#C47162']
        },
        legend: {
          show: false
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetY: 0,
            offsetX: 0
          },
          shared: false,
          intersect: true,
          enabled: true,
          x: {
            show: true,
            formatter: function (val) {
              return 'Strategy & planning: ' + val
            }
          },
          y: {
            show: true,
            formatter: function (val) {
              return 'Efficiency & performance: ' + val
            }
          },
          marker: {
            show: false
          }
        },
        title: {
          text: 'Market leading companies',
          style: {
            fontSize: '16px',
            color: '#504e70'
          }
        }
      },
      mediumSeries: [32, 28.9, 15.2, 7.2, 6.8, 5.3, 3.6, 1],
      mediumChart: {
        labels: ['Television', 'Mobile internet', 'Desktop internet', 'Newspapers', 'Outdoor', 'Radio', 'Magazines', 'Cinema' ],
        legend: {
          formatter: function (val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex] + '%'
          }
        },
        responsive: [{
          breakpoint: 400,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'top',
              offsetY: 0
            }
          }
        }, {
          breakpoint: 768,
          options: {
            chart: {
              height: 400,
              width: 300
            },
            legend: {
              position: 'top',
              offsetY: 0
            }
          }
        }],
        colors: ['#629460', '#005792', '#13334C', '#DD0A35', '#5BE7C4', '#587498', '#FD5F00', '#3399FF' ],
        title: {
          text: 'Share of global advertising market by medium',
          offsetY: 0,
          style: {
            fontSize: '16px',
            color: '#504e70'
          }
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return val + '%'
            }
          }
        }
      },
      trendsSeries: [{
        name: 'Trend popularity',
        data: [84, 65, 57, 51, 43, 28, 14]
      }],
      trendsChart: {
        colors: ['#13334C', '#005792', '#629460', '#FD5F00', '#5BE7C4', '#587498', '#DD0A35'],
        plotOptions: {
          bar: {
            distributed: true
          }
        },
        title: {
          text: 'Top advertising trends',
          style: {
            fontSize: '16px',
            color: '#504e70'
          }
        },
        xaxis: {
          categories: ['Multi-channel advertising', 'AI & Machine Learning', 'Outstream Video', 'Programmatic Advertising', 'Voice recognition marketing', 'Blockchain', 'VR & AR'],
          labels: {
            show: true,
            rotateAlways: false,
            hideOverlappingLabels: false,
            trim: false
          }

        },
        yaxis: {
          title: {
            text: 'Trend popularity'
          }
        }
      },
      marketSeries: [{
        name: 'Market size',
        data: [205.5, 92.4, 53.8, 48.9, 45.5, 25.1, 23.8, 18.1, 16.3, 14.5]
      }],
      marketChart: {
        title: {
          text: 'Top 10 advertising markets worldwide',
          style: {
            fontSize: '16px',
            color: '#504e70'
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        colors: ['#29ABA4', '#CA0000', '#DFDCE3', '#004445', '#4D638C', '#FFC952', '#003399', '#3A9AD9', '#82C12F', '#FF3B1D'],
        plotOptions: {
          bar: {
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['United States', 'China', 'Japan', 'United Kingdom', 'Germany', 'Brazil', 'South Korea', 'France', 'Australia', 'Canada'
          ]
        },
        yaxis: {
          title: {
            text: 'Market size in billion US dollars'
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' billion US dollars'
            }
          }
        }
      },
      digitalSeries: [{
        name: 'Desktop',
        data: [21.4, 23.7, 32.4, 35.6, 38.2, 40.1, 41.8, 38.1, 40.4, 42.2, 44.5]
      }, {
        name: 'Mobile',
        data: [0.2, 0.8, 1.4, 4.8, 8.3, 13.2, 23.3, 34.8, 53.7, 75.8, 78.5]
      }],
      digitalChart: {
        title: {
          text: 'Desktop vs mobile US advertising revenue',
          style: {
            fontSize: '16px',
            color: '#504e70'
          }
        },
        chart: {
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        colors: ['#13334C', '#FD5F00'],
        xaxis: {
          categories: ['2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019']
        },
        yaxis: {
          title: {
            text: 'Revenue in billion US dollars'
          }
        },
        legend: {
          position: 'bottom',
          offsetY: -10
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' billion US dollars'
            }
          }
        }
      }
    }
  }
}
</script>
